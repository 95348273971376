<template>
  <section class="cart">
    <div class="container">
      <router-link to="/catalog" class="cart__back"
        >Вернуться к покупкам
      </router-link>
      <h2 class="card__title">КОРЗИНА</h2>
      <div class="cart__body" v-if="getCart.length">
        <CartItem
          v-for="item in getCart"
          :key="item.product_id"
          :product="item"
        />
      </div>
      <div class="cart__manager" v-if="getCart.length">
        <div class="cart__coupon">
          <p>Купон на скидку:</p>
          <input
            type="text"
            ref="coupon"
            id="coupon"
            placeholder="Номер купона"
            @change="clearError"
          />
          <p class="coupon-error" v-if="getError">{{ getError }}</p>
          <button @click="getCoupon()">Пересчетать заказ</button>
        </div>
        <div class="cart__button">
          <p v-if="getCart.length">
            Итого:
            <span class="ms-5">
              <number :format="theFormat" :to="getTotal" :duration="duration" />
            </span>
          </p>
          <div v-if="getDiscountTotal" class="discount">
            <p>
              Итого со скидкой:
              <span class="ms-5">
                <number
                  :format="theFormat"
                  :to="getDiscountTotal"
                  :duration="duration"
                />
              </span>
            </p>
            <p>
              Скидка:
              <span class="ms-5">
                <number :to="discount" :duration="duration" /> %
              </span>
            </p>
            <p>
              Экономия:
              <span class="ms-5">
                <number
                  :format="theFormat"
                  :to="difference"
                  :duration="duration"
                />
              </span>
            </p>
          </div>
          <button @click="goToCheakout">Оформить заказ</button>
        </div>
      </div>
      <div class="cart-message" v-else>
        <h2>Ваша корзина пуста</h2>
      </div>
    </div>
    <section class="recommendation">
      <div class="container" v-if="getCart.length">
        <h2 class="recommendation__title">С ЭТИМ ТОВАРОМ ЧАСТО ПОКУПАЮТ</h2>
        <div class="recommendation__body">
          <!-- <SimilarProducts v-for="el in relatedProducts" :key="el.product_id" :product="el" /> -->
          <Carousel related :products="relatedProducts" />
        </div>
      </div>
    </section>
    <!-- <router-view></router-view> -->
  </section>
</template>

<script>
// import PublicAPI from "@/api/PublicAPI";
import CartItem from "@/components/cart/CartItem.vue";
import Carousel from "@/components/ui/Carousel.vue";
import { mapActions, mapGetters } from "vuex";

export default {
  data() {
    return {
      duration: 0.2,
    };
  },
  components: {
    CartItem,
    Carousel,
  },
  computed: {
    ...mapGetters("cart", [
      "discount",
      "getCart",
      "getCurrency",
      "getTotal",
      "getDiscountTotal",
      "getError",
    ]),
    ...mapGetters("products", ["relatedProducts"]),
    ...mapGetters("auth", ["isAuthenticated"]),

    total() {
      return (
        this.getTotal.toString().replace(/(.)(?=(\d{3})+$)/g, "$1 ") +
        this.getCurrency
      );
    },
    difference() {
      return this.getTotal - this.getDiscountTotal;
    },
  },
  methods: {
    ...mapActions("products", ["getRelatedProducts"]),
    ...mapActions("cart", ["removeCart", "recountOrder", "clearError"]),

    async goToCheakout() {
      // if (!this.isAuthenticated) {
      //   return this.$router.replace("/auth");
      // }
      // const request = await PublicAPI.getCartInfo("rest/cart/cart");
      // console.log(request)
      this.$router.replace("/checkout");
    },

    redirectToProduct() {
      this.$router.replace("/products");
    },

    getCoupon() {
      this.recountOrder(this.$refs.coupon.value);
    },
    // update() {
    //   this.getCart.forEach((element) => {
    //     PublicAPI.setCartChanges("rest/cart/cart", {
    //       key: element.key,
    //       quantity: element.quantity,
    //     });
    //     // .then((data) => console.log(data));
    //   });
    // },
    theFormat(number) {
      if (typeof number === "number") {
        return (
          number
            .toFixed()
            .toString()
            .replace(/(.)(?=(\d{3})+$)/g, "$1 ") + this.getCurrency
        );
      }
    },
  },

  // If user leave the page, data of quantity save by this method
  // beforeRouteLeave(to, _, next) {
  //   if (to) {
  //     this.update();
  //   }
  //   next();
  // },
  // if all items deleted from the cart, the page replace
  watch: {
    getCart(item) {
      if (item.length === 0) {
        this.removeCart();
        this.$router.replace("/catalog");
      }
    },
    "getCart.length": {
      immediate: true,
      handler(length) {
        if (length > 0) {
          const id = [];
          for (let item of this.getCart) {
            id.push(item.product_id);
          }
          this.getRelatedProducts(id);
        }
      },
    },
  },
};
</script>

<style scoped>
@import url("https://fonts.googleapis.com/css2?family=Exo+2:wght@300;400;500;600;700&display=swap");

.container {
  max-width: 1068px;
  margin: 0 auto;
  padding: 0 10px;
}

.cart h2 {
  font-weight: 600;
  font-size: 30px;
  line-height: 36px;
  text-transform: uppercase;
}
.cart a {
  font-weight: 300;
  font-size: 12px;
  line-height: 14px;
  color: #000;
  display: inline-block;
}

.cart__manager {
  display: flex;
  justify-content: space-between;
  margin-top: 35px;
}

.cart__coupon {
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  margin-top: 10px;
}

.cart__coupon input {
  font-weight: 400;
  font-size: 12px;
  text-align: center;
  padding: 15px 70px;
  border: 1px solid #75b051;
  border-radius: 5px;
}

.coupon-error {
  color: red;
}

.cart__coupon input::placeholder {
  color: #75b051;
}

.cart__coupon button {
  margin-top: 12px;
  padding: 10px 70px;
  text-align: center;
  background-color: #75b051;
  border-radius: 5px;
  color: #ffffff;
}

.cart__button {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
}
.cart__button p {
  font-size: 18px;
  line-height: 22px;
  color: #606060;
}
.cart__button span {
  font-weight: 600;
  font-size: 30px;
  line-height: 36px;
  text-transform: uppercase;
  color: black;
}
.cart__button button {
  margin-top: 50px;
  background-color: #75b051;
  border-radius: 5px;
  font-size: 22px;
  line-height: 28px;
  text-align: center;
  color: #ffffff;
  padding: 30px 80px;
}

.cart-message {
  padding: 50px 0;
  color: #7a7a7a;
  text-align: center;
}

.recommendation {
  padding-top: 125px;
  padding-bottom: 35px;
  width: 100%;
}
.recommendation__title {
  padding: 50px 0;
  font-weight: 500;
  font-size: 25px;
  line-height: 30px;
  text-transform: uppercase;
  color: #2b2b2b;
}
.recommendation__body {
  display: flex;
  justify-content: center;
  column-gap: 60px;
  width: 100%;
  /* grid-template-columns: repeat(4, 1fr);
  grid-gap: 60px; */
}
.recommendation__card {
  cursor: pointer;
  max-width: 150px;
}
.recommendation__card {
  max-width: 100%;
}
.recommendation__card h3 {
  font-weight: 600;
  font-size: 18px;
  line-height: 22px;
  text-transform: uppercase;
}
.recommendation__card p {
  font-weight: 300;
  font-size: 13px;
  line-height: 27px;
  color: #7a7a7a;
}
.recommendation__card h4 {
  font-weight: 300;
  font-size: 13px;
  line-height: 27px;
  color: #7a7a7a;
}

.discount {
  text-align: end;
}

@media (max-width: 678px) {
  .cart__button {
    flex: 1 1 100%;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    margin-top: 20px;
  }

  .cart__button p {
    padding: 0;
  }
  .recommendation {
    padding: 40px 0;
  }
}

@media (max-width: 425px) {
  .cart__coupon {
    width: 100%;
    max-width: 100%;
  }
  .cart__button {
    justify-content: center;
  }
  .cart__button button,
  .cart__coupon input,
  .cart__coupon button {
    padding: 10px 50px;
  }
}
</style>
