<template>
  <div class="card-cart">
    <div class="card-cart__img" @click="productDescription">
      <img :src="product.thumb" alt="" />
    </div>

    <div class="card-cart__content">
      <div class="card-cart__about">
        <p class="article">Артикул: {{ product.sku }}</p>
        <h3>{{ product.name }}</h3>
        <p>{{ product.meta_description }}</p>
        <p>{{ product.weight }} шт. в упаковке</p>
      </div>

      <div class="card-cart__control">
        <div class="card-cart__like">
          <img src="@/assets/img/icon-like.svg" alt="" />
        </div>
        <div class="card-cart__itter">
          <span class="bordered" @click="decrement"> - </span>
          <div>
            <input
              type="text"
              :value="product.quantity"
              @change="listener($event, product.product_id)"
            />
          </div>
          <span class="bordered" @click="increment"> + </span>
        </div>
      </div>

      <div class="card-cart__price">
        <h2>
          <number :format="theFormat" :to="totalItem" :duration="duration" />
        </h2>
        <img
          class="remove"
          src="@/assets/img/cancel.png"
          alt=""
          @click="removeCart(product.key)"
        />
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions } from "vuex";

export default {
  emits: ["delete"],
  data() {
    return {
      duration: 0.2,
    };
  },
  props: {
    product: {
      type: Object,
      required: true,
    },
  },
  computed: {
    totalItem() {
      return this.product.quantity * this.product.price_raw;
    },
  },
  methods: {
    ...mapActions("cart", [
      "changeQty",
      "incrementQty",
      "decrementQty",
      "removeCart",
      "setQty",
    ]),

    listener(e, id) {
      const value = +e.target.value;
      if (value) {
        this.setQty({
          id,
          qty: value,
        });
        this.changeQty({
          key: this.product.key,
          quantity: this.product.quantity,
        });
      }
    },
    productDescription() {
      this.$router.push("/products/" + this.product.product_id);
    },
    increment() {
      this.incrementQty(this.product.product_id);
      this.changeQty({
        key: this.product.key,
        quantity: this.product.quantity,
      });
    },
    decrement() {
      this.decrementQty(this.product.product_id);
      this.changeQty({
        key: this.product.key,
        quantity: this.product.quantity,
      });
    },
    theFormat(number) {
      if (typeof number === "number") {
        return (
          number
            .toFixed()
            .toString()
            .replace(/(.)(?=(\d{3})+$)/g, "$1 ") + this.product.currency
        );
      }
    },
  },
};
</script>

<style scoped>
h2 {
  font-weight: 600;
  font-size: 30px;
  line-height: 36px;
  text-transform: uppercase;
  margin-bottom: 0;
}

.card-cart {
  margin-top: 57px;
  border: 1px solid #c4c4c4;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 20px 80px 20px 20px;
}

.card-cart__img {
  flex: 1 1 40%;
  max-width: 200px;
  cursor: pointer;
}
.card-cart__img img {
  width: 100%;
  height: auto;
  min-width: 150px;
}
.card-cart__content {
  display: flex;
  flex: 1 1 60%;
  justify-content: space-around;
  align-items: center;
}

.card-cart__control {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  max-width: 150px;
}
.card-cart__about {
  max-width: 230px;
}
.card-cart__about p {
  font-weight: 300;
  font-size: 13px;
  line-height: 27px;
  color: #7a7a7a;
  margin: 0;
}
.card-cart__about .article {
  font-size: 13px;
  line-height: 27px;
  transform: translateY(-30px);
}
.cart .card-cart__about h3 {
  font-weight: 600;
  font-size: 18px;
  line-height: 22px;
  text-transform: uppercase;
}
.card-cart__like {
  cursor: pointer;
}

/* Iterator class */

.card-cart__itter {
  display: flex;
  align-items: center;
  /* border: 1px solid #7a7a7a; */
}
.card-cart__itter div {
  width: 30px;
  padding: 5px;
  border: 1px solid #7a7a7a;
  border-radius: 5px;
}
.card-cart__itter div input {
  width: 100%;
  text-align: center;
}

.card-cart__itter span:first-child {
  /* border-right: 1px solid #7a7a7a; */
  /* padding: 4px 5px; */
  font-size: 20px;
  cursor: pointer;
  padding: 5px 7px;
}
.card-cart__itter span:last-child {
  /* border-left: 1px solid #7a7a7a; */
  /* padding: 4px 5px; */
  font-size: 20px;
  cursor: pointer;
  padding: 5px 7px;
}

.card-cart__about,
.card-cart__control,
.card-cart__price {
  padding: 0 5px;
}

.calc {
  cursor: pointer;
}

.card-cart__price {
  display: flex;
  align-items: center;
}

.card-cart__price img {
  margin-left: 20px;
  width: 20px;
  height: 20px;
  cursor: pointer;
}

@media (max-width: 900px) {
  .card-cart {
    display: flex;
    padding: 20px 0 20px 20px;
  }

  .card-cart__img {
    width: 50%;
  }

  .card-cart__price,
  .card-cart__control {
    margin-right: 0;
    margin-left: auto;
  }
}

@media (max-width: 600px) {
  .card-cart__content {
    flex-direction: column;
  }
  .card-cart__about .article {
    transform: translateY(-10px);
  }

  .card-cart__control,
  .card-cart__price {
    margin: 10px auto;
  }

  @media (max-width: 425px) {
    .card-cart {
      justify-content: center;
    }
    .card-cart__img {
      margin-bottom: 10px;
    }
  }

  /* .card-cart__about,
  .card-cart__price {
    padding: 10px 0;
  } */
}
</style>
